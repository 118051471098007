@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

$width: 100%;
$primary: #e07720; 

body {
  margin: 0;
  font-family:'Roboto', 'Montserrat', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
} 

span, input, h1, select, p { 
  font-family: 'Montserrat', 'Segoe UI', sans-serif;
}

select { 
  font-size: 12px;
}

button { 
  font-family:'Roboto', 'Segoe UI', sans-serif;
} 

input, select { 
  &:focus {
    outline: none;
  }  
}

.index {
  position: relative;

  input  {    
    &:focus ~ .bar:before {
      width: $width; 
    } 
  } 
} 

.bar {
  position: relative;
  display: block;
  width: $width;
  &:before {
    content: '';
    height: 1px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $primary;
    transition: 300ms ease all;
    left: 0%;
  }
}

.eyes{ 
  position: absolute;
  cursor: pointer;
  right: 0px;  
  padding: 6px;  
  font-size: 20px;
  color: #C1272D;
}


.pencil { 
  position: absolute;
  cursor: pointer;
  right: 0px;  
  padding: 12px;  
  font-size: 14px;
}

.brand-logo {
  height: 120px;
  margin: auto;
} 

.centercontainer {
  width: 20rem;
  z-index: 1;
}

.card-center {
  max-width: 550px; 
  min-width: 300px;
  padding: 3rem;
  flex: 1 1;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 1200px) {
    box-shadow: none;
 }

}

input[type=checkbox]:checked {
  background-color: #191919 !important;  
  width: 15px !important;
  height: 15px !important;
  /* margin: 5px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  outline: 2px solid #191919;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}

input[type=checkbox] {  
  width: 15px !important;
  height: 15px !important;
  margin: -2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  outline: 2px solid #191919;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}

// Main Content
.main-content {
  transition: margin-left 300ms;
  margin-left: 180px; 
  min-height: 100vh;
  height: 100vh;
  background: #f6f6f6;
} 

main {     
  overflow-y: auto; 
} 

.layout {
  margin-top: 60px;

  &-content {
    overflow: auto;
    height: 90vh; 

    &-main {
      padding: 1rem;     
      padding-bottom: 2rem;
    }
  }
}

// Antd Switch
.ant-switch {
  background-color: red !important;
}

.ant-switch.ant-switch-checked {
  background-color: #33cd59 !important;
}

// .ant-form-item {
//   margin-bottom: 12px;
//   margin-top: 12px;
// }