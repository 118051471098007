.header {
    background: #C1272D;
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 1;  
    
    &-sub { 
        width: 100%;
        max-width: 90%;
        height: 60px;  
        margin: auto;
        display: flex;
        justify-content: space-between;

        @media only screen and (max-width: 768px) {
            span {
                display: none;
            }

            h1 {
                font-size: 1.25rem;
                line-height: 1.75rem;
            }
        }
    }
}


