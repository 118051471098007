.tab {
    background-color: #fff; 
    border-radius: 5px;
    cursor: pointer;   
    
    p {
        color: #C1272D;
        font-weight: 600; 
    }

    &-active    {
        background-color: #FF7F14;
        border-radius: 5px;
        cursor: pointer;  

        p {
            color: #fff;
            font-weight: 600;
        }
    }
}

.sales-categories {
    display: grid;
    margin-bottom: 1rem;
    gap: 0.5rem;    
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr)); 
}
 

.store {
    grid-column: span 2 / span 2; 

    &-icon {
        font-size: 22px;
        margin-top: -3px;
    }

    @media only screen and (max-width: 892px) { 
        grid-column: span 6 / span 6;
    }
}

.services {
    grid-column: span 2 / span 2;

    &-icon {
        margin-top: -2px;
    }
    
    @media only screen and (max-width: 892px) { 
        grid-column: span 6 / span 6;
    }
}

.date {
    grid-column: span 3 / span 3;
    grid-column-start: 6;
    margin: auto 0;
    white-space: nowrap;
    text-align: right;  

    @media only screen and (max-width: 892px) { 
        grid-column-start: 1;
        margin: auto;
        grid-column: span 12 / span 12;
    }
}

.days {
    grid-column: span 2 / span 2;

    &-icon {
        margin-top: -1px;
    }
    
    @media only screen and (max-width: 892px) { 
        grid-column: span 6 / span 6;
    }
}

.exportOption { 
    grid-column: span 2 / span 2; 

  
    &-icon {
        font-size: 19px;
        margin-top: -1px;
        margin-left: 2.5px;
    }

    .option {
        @media only screen and (max-width: 1200px) { 
            display: none;
        }

        @media only screen and (max-width: 892px) { 
            display: block;
        }
    }

    @media only screen and (max-width: 892px) { 
        grid-column: span 6 / span 6;
    }
}  
