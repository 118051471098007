$primary: #e07720;  

.sidebar {
    width:180px;
    position: fixed;
    left: 0; 
    height: 100%;
    background: #fff; 
    transition: width 300ms; 
    display: flex;
    flex-direction: column;
    justify-content: space-between; 

    &-header {
        padding: 1rem;
        display: flex;
        justify-content: space-between;
    } 
    
    &-menu {  
        margin-top: 60px;  

        a span:first-child {
            font-size: 0.9rem;
            padding-right: 1rem;
            color: #A4A6B3;
            padding-left: 19px;
            white-space: nowrap;
        }  
        
        ul > li > a {
            padding: 0.7rem;
            display: block;
            color: #A4A6B3;
            font-size: 1rem; 
            border-left: 4px solid #fff; 
        }
    }  

    &-footer { 
        left: 0px;
        right: 0px; 
        padding: 1rem 0.5rem; 
        width: 180px; 
        transition: width 300ms;  

        &-icon {
            margin: auto 0;
            font-size: 20px;
        }
    }
} 

.side-menu {
    span {
        padding:0 12px;
        font-size: 14px;
        font-weight: 600; 
        margin: auto;
        color: #000;
    }

    &-profile { 
        font-size: 10px;
    }

    img {
        margin-top: auto;
        margin-bottom: auto;
        height: 18px;
    }  
} 

// active link
.active-link, .sidebar-menu ul > li > a:hover {   
    background: #f6f6f6;
    border-left: 4px solid $primary !important; 
}   

#nav-toggle { 
    display: none;

    &:checked {
        + {
            .sidebar {
                width:60px; 
                display: flex;
                flex-direction: column;

                .sidebar {
                    &-menu{ 

                        img {
                            height: 1.5rem;
                            margin: auto;
                        } 
                    } 

                    &-footer {
                        width:60px; 

                        &-icon {
                            margin: auto;
                        }

                        span {
                            display: none;
                        }
                    }
                }
                
                li{
                    text-align: center;

                    span{
                        padding-right: 1rem;
                    }

                    a span:last-child{
                        display: none;
                    }    

                    .sidebar-header {
                        span{
                            display: none;
                        }  

                        .menubar-icon {
                            margin: auto
                        }
                    }

                    .side-menu {
                        margin: auto;
                    }
                }

                span {
                    padding: 0;
                    display: none;
                }
            } 
        }

        ~ .main-content{
            margin-left:60px;
            
            header{
                width: calc(100% - 60px); 
            } 
        }
    }
}  


   
@media only screen and (max-width: 1135px) {  
    .sidebar {
        width:60px;

        &-menu img {
            height: 1.5rem;
            margin: auto;
        } 

        &-footer {
            width: 60px; 

            &-icon {
                margin: auto;
            }

            span {
                display: none;
            }
        }

        li { 
            a{
                padding-left: 0rem;
            }

            span{
                padding-right: 1rem;

                &:last-child {
                    display: none;
                }
            }

            .sidebar { 
                &-header {
                    span{
                        display: none;
                    } 
                    
                    .menubar-icon {
                        margin: auto;
                    }
                } 
                
                &-menu img {
                    height: 1.5rem;
                    margin: auto 0.5rem;
                } 
            }

            .side-menu {
                margin: auto;
            }
        }
        

        span {
            padding: 0;
            display: none;
            visibility: hidden;
        }
    }    

    .main-content {
        margin-left:60px;  
        overflow: auto; 
        
        header{
            width: calc(100% - 60px);
            left: 60px;
        }
    }   
}