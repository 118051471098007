.table-list { 
    margin-top: 10px; 
    padding: 1rem;
    border: 1px solid #dcdcdc; 
    text-align: center; 
    overflow: auto;
    font-family: 'Montserrat', 'Segoe UI', sans-serif;
  
    ul {       
        font-family: 'Montserrat', 'Segoe UI', sans-serif;

        li { 
            color: #C1272D;
            font-size: 12px;
            padding: 1rem 1rem 1rem 0;
        }
    }
    
    thead { 
        color: #818181;
        font-size: 14px; 
        text-align: center;
    } 
  
    tbody { 
        tr {  
            &:nth-child(odd) {
                background-color: #F3F3F3;
            }

            td {
                margin: 0;
                padding: 0.5rem;
                font-size: 12px;

                &:first-child {
                    background-color: transparent;
                    color: #C1272D; 
                }
            }
        }  
    }

    tfoot td {
        color: #FF7F14;
        font-size: 12px;
        padding: 1rem;
        text-align: center;
        transform: translate(3px, 9px) rotate(319deg)
    }
}


  
   