table {
    width: 100%; 

    thead {
        tr {   
            color: #FF7F14;  
            text-align: center;
        }
    }

}
  
table tbody tr {
    &:hover {
        background-color: #f6f5f7;
    }
}

.table {
    &-content {
        height: 55vh;
        overflow: auto;
        border: 1px solid #dcdcdc; 
        margin-top: 1rem;
        padding: 0.5rem 1rem;

        thead tr {  
            border-bottom: 1px solid #dfe0eb;
            color: #FF7F14; 
                
        }
  
        th { 
            padding: 0.5rem;
            background-color: #fff;   
            font-size: 12px;
            display: table-cell;
            vertical-align: bottom; 
            font-weight: 400;
            font-family: 'Montserrat';
        }  

        td {    
            padding: 0.75rem 0.25rem;
        
            border-bottom: 1px solid #dfe0eb; 
            font-size: 12px; 
            vertical-align: text-top;
            font-weight: 400;
            font-family: 'Montserrat';
            white-space: nowrap;
        } 
    }

    &-salesOverview {
        width: 100%;
        font-size: 11px;
        overflow: auto;
        scrollbar-width: thin; 

        thead {  
            tr {
                th { 
                    justify-content: center;
                    font-size: 12px;
                    font-family: 'Montserrat';
                    font-weight: 400px;  
                    &:first-child { 
                        text-align: left;
                    }
                } 
            } 
        } 

        tbody {
            td {     
                text-align: center;
                font-family: 'Montserrat';

                &:first-child {
                    text-align: left; 
                    font-weight: 600;
                }
            } 
        }
        
        
        /* width */
        &::-webkit-scrollbar {
            width: 10px; 
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey; 
            border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgb(157, 156, 156); 
            border-radius: 10px; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: grey; 
        }
    }
}

@media only screen and (max-width: 768px) {
    .table {
        &-salesOverview {
            thead {
                tr {
                    th { 
                        text-align: center; 
                    }
                }
            }

            tbody {
                td {  
                    text-align: left; 
                }
            }
        }
    }
}