.default-icon {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 100%;
    margin: auto;
}

.team-addMember {
    span {
        display: block;

        @media only screen and (max-width: 480px) { 
            display: none;
        }
    }
}
    