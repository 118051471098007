// Alert
.error {
    color: #f72538;
    background: #fff3f4;
    border: 1px solid #edc2c6;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
}

.success {
    color: #0f5132;
    background: #eafff5;
    border: 1px solid #badbcc;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
}