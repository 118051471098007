.transaction {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .label {
        margin: auto;
        text-align: center; 
        display: block; 
    }

    &-doughnut {
        height: 8rem;
        margin: 0.25rem auto;  
    }
}
@media only screen and (max-width:1200px){ 
    .transaction {
        &-doughnut{
            height: 10rem;
        }
    }
}


@media only screen and (min-width:1024px){
    .transaction {
        &-doughnut{
            height: 10rem; 
            margin: none;
        }

        .label {
            display: none !important;
        }
    }
}

@media screen and (min-width: 1218px) {
    .transaction {
        &-doughnut{
            height: 6rem;
            margin: 0.25rem auto;
        } 

        .label {
            display: block !important;
        }
    }
}

@media screen and (min-width: 800px) { 
    .transaction {
        &-doughnut{
            height: 8rem;
            margin: auto;
        } 

        .label {
            display: block;
        } 
}
    
} 