.linkExpired {
    &-icon {    
        height: 35rem;
        padding: 1rem;
        display: block;

        @media only screen and (max-width: 892px) { 
            display: none;
        }
    }  
}