
.gotoPage { 
    text-align: center;
    width: 30px;
    border: 1px solid #dcdcdc;
    background: #F3F3F3;
    margin: auto 0.5rem;
    padding: 1px 0;
    font-size: 0.75rem; /* 12px */
    line-height: 1rem; /* 16px */
}

.pagination-team {
    display: flex;
    margin-top: 1rem;

    @media only screen and (max-width: 892px) { 
        display: none;
    }
}

.rowsperpage {
    margin: auto;
    padding: 0 2px;
}